import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import QuizPage from '../QuizPage/QuizPage'
import SetupPage from '../SetupPage/SetupPage'
import SummaryPage from '../SummaryPage/SummaryPage'
import './MainComponent.scss'


function MainComponent() {

  return (
    <div className="main-component">
      <Navbar />
      <div className="content-wrapper">
        <Switch>
          <Route exact path="/" component={SetupPage} />
          <Route exact path="/quiz" component={QuizPage} />
          <Route exact path="/wyniki" component={SummaryPage} />
        </Switch>
      </div>
      <Footer />
    </div>
  )
}

export default MainComponent
