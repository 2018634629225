import React from 'react'
import { Link } from 'react-router-dom'
import './Navbar.scss'

function Navbar() {
  return (
    <div className="navbar">
      <div className="navbar-inner">
        <Link to="/">
          <img className="logo" src={`logo.jpg`} />
          QUIZ TATRZAŃSKI
        </Link>
      </div>
    </div>
  )
}

export default Navbar
