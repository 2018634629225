import React from 'react'
import './Footer.scss'

function Footer() {
  return (
    <div className="footer">
      <div className="footer-inner">
        <span>© Michał W.</span>
        <a href="http://www.projektyprzygodowe.pl" target="_blank" rel="noopener noreferrer">projektyprzygodowe.pl</a>
      </div>
    </div>
  )
}

export default Footer
