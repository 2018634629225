import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import './SetupPage.scss'


function SetupPage(props) {
  const [category, setCategory] = useState(null)
  const [level, setLevel] = useState(null)
  // const [categoryError, setCategoryError] = useState(false)
  const [levelError, setLevelError] = useState(false)

  const validateSetup = () => {
    // const categoryError = !category
    const levelError = !level

    if (
      // !categoryError && 
      !levelError
    ) {
      // sessionStorage.setItem('category', category)
      sessionStorage.setItem('level', level)
      sessionStorage.setItem('result', null)
      props.history.push('/quiz')
    }
    
    // setCategoryError(categoryError)
    setLevelError(levelError)
  }

  return (
    <div className="setup-page">
      {/* <h2>Wybierz kategorię</h2>
      <ul className="options-wrapper">
        <li className={`${category === 'trails' && 'selected'}`} onClick={() => setCategory('trails')}>szlaki</li>
        <li className={`${category === 'peaks' && 'selected'}`} onClick={() => setCategory('peaks')}>szczyty i przełęcze</li>
        <li className={`${category === 'valleys' && 'selected'}`} onClick={() => setCategory('valleys')}>doliny i stawy</li>
        <li className={`${category === 'off-trail' && 'selected'}`} onClick={() => setCategory('off-trail')}>poza szlakiem</li>
      </ul> */}

      <h2>Wybierz poziom trudności</h2>
      <ul className="options-wrapper">
        <li className={`${level === 'easy' && 'selected'}`} onClick={() => setLevel('easy')}>łatwy</li>
        <li className={`${level === 'medium' && 'selected'}`} onClick={() => setLevel('medium')}>średni</li>
        <li className={`${level === 'hard' && 'selected'}`} onClick={() => setLevel('hard')}>trudny</li>
      </ul>

      <div onClick={validateSetup} className="start-button">START</div>
      {/* { categoryError && <div className="error-message">Wybierz kategorię</div> } */}
      { levelError && <div className="error-message">Wybierz poziom trudności</div> }
    </div>
  )
}

export default withRouter(SetupPage)
