import React, { useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import './QuizPage.scss'


const QUESTIONS_PER_GAME = 10
let correctAnswers = 0

function shuffle(a) {
  let j, x, i
  for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1))
      x = a[i]
      a[i] = a[j]
      a[j] = x
  }
  return a
}


function QuizPage(props) {
  const [questions, setQuestions] = useState([])
  const [questionNumber, setQuestionNumber] = useState(1)
  const [showAnswers, setShowAnswers] = useState(false)

  useEffect(() => {
    // const category = sessionStorage.getItem('category')
    const level = sessionStorage.getItem('level')

    // console.log('category', category)
    // console.log('level', level)

    fetch(`assets/questions/${level}.json`)
      .then(res => res.json())
      .then(data => {
        setQuestions(shuffle(data).splice(0,10))
        correctAnswers = 0
      })
  }, [])

  const checkAnswer = answer => {
    if (answer.correct) {
      correctAnswers++
    }
    sessionStorage.setItem('result', `${correctAnswers}/${QUESTIONS_PER_GAME}`)
    setShowAnswers(true)
    setTimeout(() => {
      setShowAnswers(false)
      if (questionNumber < QUESTIONS_PER_GAME) {
        setQuestionNumber(questionNumber + 1)
      } else {
        props.history.push('/wyniki')
      }
    }, 1000)
  } 

  const renderProgressBar = () => {
    return <div className="progress-bar-wrapper">
      <div className="progress-bar" style={{ width: `${questionNumber / QUESTIONS_PER_GAME * 100}%` }} />
      <div className="progress-text">{questionNumber} z {QUESTIONS_PER_GAME}</div>
    </div>
  }

  return (
    <div className="quiz-page">
      { questions[questionNumber - 1] && <div className="question-wrapper">
        { renderProgressBar() }
        <img className="question-image" src={`assets/images/${questions[questionNumber - 1].image}`} />
        <h2>{questions[questionNumber - 1].question}</h2>
        <div className="answers-wrapper">
        {
          questions[questionNumber - 1].answers.map((answer, i) => <div className={`answer ${showAnswers && answer.correct && 'correct-answer'} ${showAnswers && !answer.correct && 'wrong-answer'}`} key={i} onClick={() => checkAnswer(answer)}>
            {answer.text}
          </div>)
        }
        </div>
        <Link className="back-link" to="/">&lt; Zrezygnuj i wróć do strony głównej</Link>
      </div> }
    </div>
  )
}

export default withRouter(QuizPage)
