import React from 'react'
import { Link } from 'react-router-dom'
import './SummaryPage.scss'

function SummaryPage() {
  return (
    <div className="summary-page">
      <div className="summary-box">
        <div className="result-header">Twój wynik to</div> 
        <div className="result">{sessionStorage.getItem('result')}</div>
        <Link to="/">&lt; Wróć do strony głównej</Link>
      </div>
      <div className="blog-info">
        <div>Chcesz poczytać o Tatrach i innych górach?</div>
        <div>Zapraszam na blog <a href="https://www.projektyprzygodowe.pl" target="_blank" rel="noopener noreferrer">projektyprzygodowe.pl</a></div>
      </div>
    </div>
  )
}

export default SummaryPage
